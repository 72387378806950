import React from "react";
import styled from "styled-components";
import PotokIcon from "assets/svgs/graph-design/graph-design-potok-icon.svg";
import DateIcon from "assets/svgs/graph-design/graph-design-date-icon.svg";

export const Section = styled.section`
  margin-bottom: 2vw;
`;

export const Wrapper = styled.div`
  background: white;
  border-radius: 0.6vw;
  padding: 4vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 575px) {
    padding: 5vw 0vw;
    border-radius: 2.5vw;
    align-items: start;
  }
`;

export const Illustrations = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3vw;

  svg {
    width: 12vw;
    min-width: 12vw;
    box-shadow: 0 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.15);

    :first-child {
      margin-right: 1vw;
      transform: rotate(-11deg);
      z-index: 2;
    }
    :last-child {
      transform: rotate(15deg);
      // left: -1vw;
      top: 0.2vw;
      position: relative;
    }
  }

  @media only screen and (max-width: 575px) {
    justify-content: center;
    // width: 100%;
    padding: 0 5vw;
    margin-bottom: 8vw;
    padding-top: 6vw;
    padding-left: 6vw;

    svg {
      width: 35vw;
      min-width: 35vw;
      box-shadow: 0 2vw 2vw 0 rgba(0, 0, 0, 0.15);

      :first-child {
        margin-right: 3vw;
      }
      :last-child {
        left: -5vw;
        top: 1vw;
      }
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  margin-bottom: 2vw;
  svg {
    width: 7vw;
    height: 7vw;
  }
  svg {
    :first-child {
      width: 10vw;
      margin-right: 2vw;
    }
  }
  @media only screen and (max-width: 575px) {
    margin-bottom: 6vw;
    svg {
      width: 12vw;
      height: 12vw;
    }
    svg {
      :first-child {
        width: 17.5vw;

        margin-right: 2vw;
      }
    }
  }
`;

export const Potok = styled(PotokIcon)`
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 0.3vw;
  fill: white;
  @media only screen and (max-width: 575px) {
    width: 4.3vw;
    height: 4vw;
    margin-right: 1vw;
    margin-top: -0.3vw;
  }
`;

export const Date = styled(DateIcon)`
  width: 1.3vw;
  height: 1.3vw;
  fill: var(--granich-red);
  margin-right: 0.3vw;
  @media only screen and (max-width: 575px) {
    width: 4.3vw;
    height: 4vw;
    margin-right: 1vw;
    margin-top: -0.8vw;
  }
`;

export const MainImage = styled.img`
  position: absolute !important;
  width: auto;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  user-select: none;
  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
    position: relative !important;
    width: 100% !important;
    ${({ courseTitle }) =>
      courseTitle === "Фундамент Графдизайна" &&
      `
      margin-bottom: 0;
    `}
  }
`;

export const Tags = styled.div`
  display: flex;
  // margin-bottom: 1vw;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  // max-width: 65%;
  z-index: 1;
  @media only screen and (max-width: 575px) {
    padding: 0 5vw;
    max-width: 100%;
    margin-top: 2vw;
  }
`;
export const Tag = styled.div`
  font-size: 1.2vw;
  display: flex;
  color: var(--granich-red);
  border: 1.5px solid var(--granich-red);
  padding: 0.3vw 0.8vw;
  border-radius: 100vw;
  margin-right: 0.5vw;
  margin-bottom: 1vw;
  font-weight: 500;
  align-items: center;
  :last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
    padding: 1.8vw 2.5vw 1.6vw;
    margin-right: 3vw;
    white-space: nowrap;
    margin-bottom: 3vw;
    line-height: 1;
  }
`;
export const MainTag = styled.div`
  font-size: 1.2vw;
  display: flex;
  color: white;
  background: var(--granich-red);
  border: 1.5px solid var(--granich-red);
  padding: 0.3vw 0.8vw;
  border-radius: 100vw;
  margin-right: 0.5vw;
  font-weight: 500;
  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
    padding: 1.6vw 2.5vw;
    margin-right: 3vw;
    white-space: nowrap;
    margin-bottom: 3vw;
  }
`;

export const Title = styled.h1`
  line-height: 1;
  margin-bottom: 3vw;
  position: relative;
  text-align: center;

  width: 80%;
  letter-spacing: -0.2vw !important;
  font-size: 6vw;
  line-height: 1.05;
  font-family: Inter;
  font-weight: 800;
  margin-top: 0.5vw;
  white-space: pre;

  p {
    font-size: 7.5vw;
    font-family: EB Garamond;
    style: normal;
    font-weight: 500;

    i {
      display: block;
      letter-spacing: -0.05vw;
      line-height: inherit;
      font-style: italic;
      font-size: 7.2vw;
      font-weight: inherit;
      font-family: EB Garamond;
    }
  }

  @media only screen and (max-width: 575px) {
    line-height: 1.1;
    padding: 0 5vw;
    text-align: left;
    margin-bottom: 5vw;
    font-size: 11vw;
    margin-top: 3vw;
    width: 100%;
    white-space: normal;

    p {
      font-size: 15vw;
      letter-spacing: -0.1vw;
      i {
        letter-spacing: -0.2vw;
        font-size: 15.5vw;
        margin-top: 2vw;
      }
    }
  }
`;
export const Descr = styled.div`
  width: 52vw;
  font-size: 1.35vw;
  margin-bottom: 2.5vw;
  margin-left: 0.8vw;
  line-height: 1.47;
  font-weight: 500;
  position: relative;
  z-index: 1;
  text-align: center;

  p {
    letter-spacing: 0 !important;
    font-size: 7vw;
    line-height: 1.05;
  }

  @media only screen and (max-width: 575px) {
    width: 95%;
    font-size: 4.6vw;
    padding: 0 5vw;
    margin-bottom: 5vw;
    margin-left: 0;
    text-align: left;

    p {
      font-size: 15vw;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AdditionalText = styled.div`
  font-size: 1vw;
  width: 17vw;
  margin-left: 1.5vw;
  line-height: 1.45;
  font-weight: 500;
  @media only screen and (max-width: 575px) {
    width: 90%;
    font-size: 3.7vw;
    padding: 0 3vw;
    margin-top: 2vw;
  }
`;
