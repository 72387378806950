import React from "react";
import Masonry from "components/pages/course-page/portfolio/Masonry";
import { Container } from "components/style";
import * as SC from "./style";

const CopywritingPortfolio = ({ portfolio, courseTitle }) => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <SC.SectionTitle>Некоторые работы учеников</SC.SectionTitle>
        <SC.SectionDescr>
          В этих работах ученики делали не только визуальный слой,
          но и смысловой слой (текстовый).
        </SC.SectionDescr>
        <Masonry courseTitle={courseTitle} portfolio={portfolio} />
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default CopywritingPortfolio;
