/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useCopywritingPortfolio = () => {
  const query = useStaticQuery(graphql`
    query copywritingPortfolio {
      poster1: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/02.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/03.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/04.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/05.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/06.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/07.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/08.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(
        relativePath: { eq: "graphic-design-copywriting/portfolio/09.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0asdsdsaa",
      src: portfolio[8].childImageSharp.fluid,
      author: "Надежда Кудря",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53ва5",
      src: portfolio[6].childImageSharp.fluid,
      author: "Дмитрий Новиков",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2ыв3576",
      src: portfolio[7].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Алиса Брюква",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Александра Буяк",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Анастасия Курганская",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Надежда Кудря",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Владислав Садовский",
    },
  ];

  return data;
};

export default useCopywritingPortfolio;
