import React from "react";
import { Button } from "components/pages/course-page/offer/style";
import CourseArrowDown from "assets/svgs/inter-arrow.svg";
import PainAndBenefitIllustration from "assets/svgs/graphic-design-copywriting/pain_and_benefit.svg";
import PainAndBenefit2Illustration from "assets/svgs/graphic-design-copywriting/pain_and_benefit_2.svg";
import { Container } from "components/style";
import {
  StyledCourseStatusTag,
  StyledTag,
} from "components/pages/course-page/offer/course-tags/style";
import { getCourseStatusIcon } from "utilities/course-utils";
import COURSE_STATUSES from "constants/course-statuses";
import * as SC from "./style";

const CopywritingOffer = ({ data, courseTitle }) => {
  const tags = data.body.find(el => el.slice_type === "tags").items;

  return (
    <SC.Section>
      <Container>
        <SC.Wrapper>
          <SC.Illustrations>
            <PainAndBenefitIllustration />
            <PainAndBenefit2Illustration />
          </SC.Illustrations>
          <SC.Tags>
            <StyledTag
              courseType="Мастер-класс"
              style={{ marginBottom: "1vw" }}
            >
              Мастер-класс
            </StyledTag>
            <StyledTag
              courseType="Мастер-класс"
              style={{ marginBottom: "1vw" }}
            >
              Маркетинг
            </StyledTag>
            <StyledCourseStatusTag courseStatus={COURSE_STATUSES.open}>
              {getCourseStatusIcon(true)}В продаже
            </StyledCourseStatusTag>
          </SC.Tags>
          <SC.Title>{courseTitle ?? data.courseTitle}</SC.Title>
          <SC.Descr>
            Основы маркетинга для графических дизайнеров. Вы научитесь делать
            коммерческий плакат для увеличения продаж продукта (текст и дизайн).
          </SC.Descr>
          <SC.ButtonWrapper>
            <Button to="#participation-section">
              Купить
              <CourseArrowDown />
            </Button>
          </SC.ButtonWrapper>
        </SC.Wrapper>
      </Container>
    </SC.Section>
  );
};

export default CopywritingOffer;
