/* eslint-disable */
const PRICE_RANGE = {
  pdfPresentation: {
    title: "2 презентации уроков",
  },
  courseVideo: {
    title: "2 видео по урокам",
  },
  homework: {
    title: "Задания для практики",
  },
  portfolio: {
    title: "Макет в портфолио",
  },
  support: {
    title: "Поддержка куратора",
  },
};
const priceRange = [
  {
    pricesTitle: "Видеоформат",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: false,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: false,
      },
      {
        text: PRICE_RANGE.support.title,
        isActive: false,
      },
    ],
    price: 2900,
    getcourseInputId: "4614180",
  },
  {
    pricesTitle: "Практика с поддержкой",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.support.title,
        isActive: true,
      },
    ],
    price: 3900,
    getcourseInputId: "4614229",
  },
];
export default {
  priceRange: priceRange,
  copyrightCurriculumHeader: {
    title: "Программа мастер-класса",
    lessonsCount: {
      number: 2,
      text: "Насыщенных лекции",
    },
  },
  copyrightCurriculum: [
    {
      title: "Начало маркетинга",
      titleDescription:
        "В рамках 1 урока мы изучим пошаговую схему сборки оффера (сильное предложение о покупке продукта). Схема состоит из четырёх ключевых шагов — ядро продукта, конкурентные преимущества, позиционирование и оффер.",
      tagName: "1 лекция",
    },
    {
      title: "Базовая композиция копирайтинга",
      titleDescription:
        "Во 2 уроке мы посмотрим как готовый оффер разрезается на композицию коммерческого плаката. То есть, займёмся переводом из смыслового (текстового) слоя в визуальный.",
      tagName: "2 лекция",
    },
  ],
};
