// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CopywritingOffer from "components/pages/graphic-design-copywriting/offer";
import CopywritingPortfolio from "components/pages/graphic-design-copywriting/portfolio";
import CoursePriceRange from "components/pages/course-page/price-range";
import CourseCurriculum from "components/pages/course-page/lessons-order";
import PaymentChoices from "components/pages/payment-choices";
// assets
import ogImage from "assets/images/seo/graphic-system-copywriting.jpg";
import ogImageVK from "assets/images/seo/vk/graphic-system-copywriting.jpg";
// data
import localData from "data/pages/graphic-design-copywriting";
// hooks
import useCopywritingPortfolio from "hooks/portfolio/useCopywritingPortfolio";
import useCopywritingQuery from "hooks/graphql/useCopywritingQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

const GraphicDesignCopywritingPage = () => {
  const prismicData = useCopywritingQuery();

  const portfolio = useCopywritingPortfolio();

  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout>
      <SEO
        title="Графдизайн Копирайтинга"
        description="Основы маркетинга для графических дизайнеров. Вы научитесь делать коммерческий плакат для увеличения продаж продукта (текст и дизайн)."
        socialDescription={
          seoData?.seodescription?.text ||
          "Основы маркетинга для графических дизайнеров. Вы научитесь делать коммерческий плакат для увеличения продаж продукта (текст и дизайн)."
        }
        keywords={[
          "копирайтинг",
          "гранич",
          "copywriting",
          "granich",
          "графсистема",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicDesignCopywriting)}
      />
      <div className="section-top-block" />
      <CopywritingOffer courseTitle={"Графдизайн\nКопирайтинга"} data={data} />
      <CopywritingPortfolio
        portfolio={portfolio}
        courseTitle="Графдизайн Копирайтинга"
      />
      <CourseCurriculum
        dataHeader={localData.copyrightCurriculumHeader}
        data={localData.copyrightCurriculum}
        courseTitle="Графдизайн Копирайтинга"
        withSubtitle
      />
      <div id="participation-section" />
      <CoursePriceRange
        formId="ltForm3153227"
        priceRange={localData.priceRange}
        courseTitle={data.coursetitle?.text}
        formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1758315021"
        customData={localData.priceRange}
        disableTooltips
      />
      <PaymentChoices courseType="Мастер-класс" />
    </Layout>
  );
};

export default GraphicDesignCopywritingPage;
